import React from 'react';

import {FontAwesomeIcon} from "@fortawesome/react-fontawesome"
import {faSpinner} from "@fortawesome/free-solid-svg-icons";
import dayjs from "dayjs";
import {useSelector} from "react-redux";
import {useLocation, Link} from "react-router-dom";

import Paginator from "../components/Paginator";
import {useAsyncEffect} from "../effect";
import {useDispatch} from "../store";
import {getNoticeList, selectNoticeMap, selectTotal} from "../store/notice";
import './NoticeList.css';

const LIMIT = 20;
function NoticeList() {
	const dispatch = useDispatch();

	const query = new URLSearchParams(useLocation().search);
	const [isLoading, setIsLoading] = React.useState(false);
	const [idList, setIdList] = React.useState([] as number[]);

	const page = parseInt(query.get("page") ?? "1");
	const offset = (page - 1) * LIMIT;

	const total = useSelector(selectTotal);
	const noticeMap = useSelector(selectNoticeMap);
	const noticeList = idList.map((id) => noticeMap[id]).filter((id) => id != null);

	useAsyncEffect(async () => {
		setIsLoading(true);
		const response = await dispatch(getNoticeList(offset, LIMIT));
		setIdList(response.data.map((notice) => notice.id));
		setIsLoading(false);
	}, [offset]);

	return (
		<div className="notice-list">
			<div className="section">
				<div className="container">
					<h1 className="title">공지사항</h1>
					<hr />
					<table>
						<thead>
							<tr>
								<th>번호</th>
								<th>제목</th>
								<th>등록일</th>
							</tr>
						</thead>
						<tbody>
							{
								!isLoading ?
								noticeList.map((notice) => (
								<tr key={notice.id}>
									<td className="notice-id">{notice.id}</td>
									<td className="notice-title">
										<Link to={`/notice/${notice.id}`}>{notice.title}</Link>
									</td>
									<td className="notice-time">{dayjs.unix(notice.timestamp).format("YYYY.MM.DD")}</td>
								</tr>
							)) :
							(
								<tr className="notice-is-loading">
									<td colSpan={100}>
										<div className="loading">
											<FontAwesomeIcon icon={faSpinner} spin size="2x" />
										</div>
									</td>
								</tr>
							)
						}
						</tbody>
					</table>
					<Paginator total={total} page={1} limit={LIMIT} href={(page) => `/notice?page=${page}`} />
				</div>
			</div>
		</div>
	);
}

export default NoticeList;
