import React from 'react';

import {FontAwesomeIcon} from "@fortawesome/react-fontawesome"
import {faBars} from "@fortawesome/free-solid-svg-icons";
import {Link} from "react-router-dom";

import logo from "../assets/logo.png";

import "./Header.css";

function Header() {
	const [expand, setExpand] = React.useState(false);

	const onExpandClick = React.useCallback(() => {
		setExpand(!expand);
	}, [expand]);

	return (
		<header className="container">
			<nav className="navbar is-tablet">
				<div className="navbar-brand">
					<Link to="/" className="navbar-item">
						<img src={logo} />
					</Link>
					<div className="is-hidden-desktop navbar-mobile-buttons">
						<div className="navbar-mobile-button" onClick={onExpandClick}>
							<FontAwesomeIcon icon={faBars} size="lg" />
						</div>
					</div>
				</div>
				<div className="navbar-menu">
					<div className="navbar-end">
						<Link to="/notice" className="navbar-item">
							공지사항
						</Link>
						<Link to="/lecture" className="navbar-item">
							강의 목록
						</Link>
					</div>
				</div>
			</nav>
			{
				expand ?
				(
					<div className="is-hidden-desktop header-mobile-menu">
						<Link to="/notice">	공지사항</Link>
						<Link to="/lecture">강의 목록</Link>
					</div>
				) :
				null
			}
		</header>
	);
}

export default Header;
