import React from 'react';

import {FontAwesomeIcon} from "@fortawesome/react-fontawesome"
import {faSpinner} from "@fortawesome/free-solid-svg-icons";
import dayjs from "dayjs";
import {useSelector} from "react-redux";
import {useParams, Link} from "react-router-dom";

import Youtube from "../components/Youtube";
import {useAsyncEffect} from "../effect";
import {useDispatch} from "../store";
import {getLecture, selectLecture} from "../store/lecture";
import './Lecture.css';

function Lecture() {
	const dispatch = useDispatch();

	const params = useParams<{id: string}>();
	const id = parseInt(params.id);
	const lecture = useSelector(selectLecture(id));

	const [isLoading, setIsLoading] = React.useState(true);

	useAsyncEffect(async () => {
		if (lecture == null) {
			setIsLoading(true);
			await dispatch(getLecture(id));
		}
		setIsLoading(false);
	}, [id, lecture])

	let body: React.ReactNode;
	if (isLoading) {
		body = (
			<div className="loading">
				<FontAwesomeIcon icon={faSpinner} spin size="2x"/>
			</div>
		)
	} else if (lecture != null) {
		let media: React.ReactNode;
		if (lecture.media == null) {
			media = <strong>동영상이 아직 준비되지 않았습니다.</strong>;
		} else {
			media = <Youtube media={lecture.media} />;
		}

		body = (
			<>
				<div className="lecture-view-title">
					<h2 className="lecture-view-title-text">{lecture.title}</h2>
					<span className="lecture-view-title-date">
						{dayjs.unix(lecture.timestamp).format("YYYY.MM.DD")}
					</span>
				</div>
				<div className="lecture-view-description">
					{media}
					<p>{lecture.description}</p>
				</div>
			</>
		);
	} else {
		body = <span>존재하지 않는 강의입니다.</span>;
	}

	return (
		<div className="lecture-view">
			<div className="section">
				<div className="container">
					<h1 className="title">강의 보기</h1>
					<hr />
					<div className="lecture-view-body">
						{body}
					</div>
					<div className="lecture-view-buttons">
						<Link to="/lecture" className="button">목록</Link>
					</div>
				</div>
			</div>
		</div>
	);
}

export default Lecture;
