import React from 'react';

import {Switch, Redirect, Route} from "react-router-dom";

import Banner from "./components/Banner";
import Footer from "./components/Footer";
import Header from "./components/Header";
import Home from "./pages/Home";
import Lecture from "./pages/Lecture";
import LectureList from "./pages/LectureList";
import Notice from "./pages/Notice";
import NoticeList from "./pages/NoticeList";

import "./App.css";

function App() {
	return (
		<div className="root">
			<Header />
			<Banner />
			<Switch>
				<Route path="/" exact>
					<Home />
				</Route>
				<Route path="/lecture" exact>
					<LectureList />
				</Route>
				<Route path="/lecture/:id">
					<Lecture />
				</Route>
				<Route path="/notice" exact>
					<NoticeList />
				</Route>
				<Route path="/notice/:id">
					<Notice />
				</Route>
				<Route>
					<Redirect to="/" />
				</Route>
			</Switch>
			<Footer />
		</div>
	);
}

export default App;
