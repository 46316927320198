import React from 'react';

import {FontAwesomeIcon} from "@fortawesome/react-fontawesome"
import {faSpinner} from "@fortawesome/free-solid-svg-icons";
import dayjs from "dayjs";
import {useSelector} from "react-redux";
import {Link} from "react-router-dom";

import {useAsyncEffect} from "../effect";
import {useDispatch} from "../store";
import {getNoticeList, selectNoticeMap} from "../store/notice";

import "./Notice.css";

function Notice() {
	const dispatch = useDispatch();

	const [isLoading, setIsLoading] = React.useState(false);
	const [idList, setIdList] = React.useState([] as number[]);

	const noticeMap = useSelector(selectNoticeMap);
	const noticeList = idList.map((id) => noticeMap[id]).filter((id) => id != null);

	useAsyncEffect(async () => {
		setIsLoading(true);
		const response = await dispatch(getNoticeList(0, 4));
		setIdList(response.data.map((notice) => notice.id));
		setIsLoading(false);
	}, []);

	return (
		<div className="home-notice custom-box">
			<h2 className="title">
				<Link to="notice">공지사항</Link>
			</h2>
			<hr />
			{
				!isLoading ?
				noticeList.map((notice) => (
					<Link to={`/notice/${notice.id}`} className="home-notice-item" key={notice.id}>
						<strong className="home-notice-title">{notice.title}</strong>
						<span className="home-notice-date">
							{dayjs.unix(notice.timestamp).format("MM-DD")}
						</span>
					</Link>
				)) :
				(
					<div className="loading">
						<FontAwesomeIcon icon={faSpinner} spin size="2x"/>
					</div>
				)
			}
		</div>
	);
}

export default Notice;
