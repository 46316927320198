import React from 'react';

import {FontAwesomeIcon} from "@fortawesome/react-fontawesome"
import {faYoutube} from "@fortawesome/free-brands-svg-icons";
import Video from "react-youtube";

import './Youtube.css';

let DOMAIN: string;
if (process.env.NODE_ENV === "production") {
	DOMAIN = "www.kiaedulive.com";
} else {
	DOMAIN = "localhost";
}

type Props = {
	media: string;
};
function Youtube(props: Props) {
	const {media} = props;

	const userAgent = navigator.userAgent;
	if (/android/i.test(userAgent)) {
		return (
			<div className="youtube">
				<a className="button is-outlined youtube-link" href={`vnd.youtube://${media}`}>
					<FontAwesomeIcon className="youtube-link-icon" icon={faYoutube} size="lg" />
					Youtube 앱에서 보기
				</a>
			</div>
		);
	} else if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
		return (
			<div className="youtube">
				<a className="button is-outlined youtube-link" href={`youtube://${media}`}>
					<FontAwesomeIcon className="youtube-link-icon" icon={faYoutube} size="lg" />
					Youtube 앱에서 보기
				</a>
			</div>
		);
	} else {
		return (
			<div className="youtube">
				<Video videoId={media} containerClassName="youtube-video" />
			</div>
		);
	}
}

export default Youtube;
