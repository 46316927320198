import React from 'react';

import "./Banner.css";

function Banner() {
	return (
		<div className="hero">
			<div className="hero-body" />
		</div>
	);
}

export default Banner;
