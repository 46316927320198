import React from 'react';

import "./Footer.css";

function Footer() {
	return (
		<footer className="footer">
			<div className="content">
				이 페이지에는 네이버에서 제공한 나눔글꼴이 적용되어 있습니다
			</div>
		</footer>
	);
}

export default Footer;
