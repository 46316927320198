import React from 'react';

import {FontAwesomeIcon} from "@fortawesome/react-fontawesome"
import {faSpinner} from "@fortawesome/free-solid-svg-icons";
import dayjs from "dayjs";
import {Link} from "react-router-dom";

import {useAsyncEffect} from "../effect";
import {useDispatch, useSelector} from "../store";
import {getLectureList, selectLectureMap} from "../store/lecture";

import "./LectureBoard.css";

function LectureBoard() {
	const dispatch = useDispatch();

	const [isLoading, setIsLoading] = React.useState(true);
	const [idList, setIdList] = React.useState([] as number[]);

	const lectureMap = useSelector(selectLectureMap);
	const [first, ...rest] = idList.map((id) => lectureMap[id]).filter((id) => id != null);

	useAsyncEffect(async () => {
		setIsLoading(true);
		try {
			const response = await dispatch(getLectureList(0, 6));
			setIdList(response.data.map((lecture) => lecture.id));
		} catch (e) {
			// Do nothing
		}
		setIsLoading(false);
	}, []);

	let body: React.ReactNode;
	if (isLoading) {
		body = (
			<div className="loading">
				<FontAwesomeIcon icon={faSpinner} spin size="2x"/>
			</div>
		);
	} else {
		let firstNode: React.ReactNode = null;
		if (first != null) {
			const lines = first.description.split("\n");
			const shortDesc = lines.slice(3).join("\n") + "\n...";

			firstNode = (
				<div className="column is-7">
					<Link to={`/lecture/${first.id}`} className="media">
						<div className="media-left">
							<img
								className="home-lecture-thumbnail"
								src={`https://img.youtube.com/vi/${first.media}/hqdefault.jpg`}
							/>
						</div>
						<div className="media-content">
							<div className="content">
								<strong>{first.title}</strong>
								<span className="home-lecture-description">{shortDesc}</span>
							</div>
						</div>
					</Link>
				</div>
			);
		}

		body = (
			<div className="columns is-tablet">
				{firstNode}
				<div className="column home-lecture-list">
					{rest.map((lecture) => (
						<Link to={`/lecture/${lecture.id}`} className="home-lecture-item" key={lecture.id}>
							<span className="home-lecture-title">{lecture.title}</span>
							<span className="home-lecture-date">
								{dayjs.unix(lecture.timestamp).format("MM-DD")}
							</span>
						</Link>
					))}
				</div>
			</div>
		);
	}

	return (
		<div className="home-lecture custom-box">
			<h2 className="title">
				<Link to="/lecture">강의 목록</Link>
			</h2>
			<hr className="title-underbar" />
			{body}
		</div>
	);
}

export default LectureBoard;
