import React from 'react';

import {FontAwesomeIcon} from "@fortawesome/react-fontawesome"
import {faSpinner} from "@fortawesome/free-solid-svg-icons";
import dayjs from "dayjs";
import {useSelector} from "react-redux";
import {useParams, Link} from "react-router-dom";

import {useAsyncEffect} from "../effect";
import {useDispatch} from "../store";
import {getNotice, selectNotice} from "../store/notice";
import './Notice.css';

function Notice() {
	const dispatch = useDispatch();

	const params = useParams<{id: string}>();
	const id = parseInt(params.id);
	const notice = useSelector(selectNotice(id));

	const [isLoading, setIsLoading] = React.useState(true);

	useAsyncEffect(async () => {
		if (notice == null) {
			setIsLoading(true);
			await dispatch(getNotice(id));
		}
		setIsLoading(false);
	}, [id, notice])

	let body: React.ReactNode;
	if (isLoading) {
		body = (
			<div className="loading">
				<FontAwesomeIcon icon={faSpinner} spin size="2x"/>
			</div>
		)
	} else if (notice != null) {
		body = (
			<>
				<div className="notice-view-title">
					<h2 className="notice-view-title-text">{notice.title}</h2>
					<span className="notice-view-title-date">
						{dayjs.unix(notice.timestamp).format("YYYY.MM.DD")}
					</span>
				</div>
				<div className="notice-view-description">
					<p>{notice.description}</p>
				</div>
			</>
		);
	} else {
		body = <span>존재하지 않는 공지입니다.</span>;
	}

	return (
		<div className="notice-view">
			<div className="section">
				<div className="container">
					<h1 className="title">공지사항</h1>
					<hr />
					<div className="notice-view-body">
						{body}
					</div>
					<div className="notice-view-buttons">
						<Link to="/notice" className="button">목록</Link>
					</div>
				</div>
			</div>
		</div>
	);
}

export default Notice;
