import {createSelector} from "reselect";
import {createAction, createReducer} from "typesafe-actions";

import * as api from "../api";
import {Notice} from "../types";
import type {AsyncThunkAction, State as RootState} from "./index";
import {createSubReducer} from "./util";

export type State = {
	total: number;
	notice: Record<Notice["id"], Notice>;
};

const initial: State = {
	total: 1,
	notice: {},
};

export const selector = (state: RootState): State => state.notice;
export const selectTotal = createSelector(selector, (state) => state.total);
export const selectNoticeMap = createSelector(selector, (state) => state.notice);
export const selectNotice = (id: Notice["id"]) => (state: RootState) => selectNoticeMap(state)[id];

export const setTotal = createAction("NOTICE/TOTAL/SET")<number>();
export const delNotice = createAction("NOTICE/DEL")<Notice["id"]>();
export const setNotice = createAction("NOTICE/SET")<Notice>();

export type Action =
	| ReturnType<typeof setTotal>
	| ReturnType<typeof delNotice>
	| ReturnType<typeof setNotice>;
export const reducer = createReducer<State, Action>(initial, {
	"NOTICE/TOTAL/SET": createSubReducer((state, action) => {
		const total = action.payload;
		state.total = total;
	}),
	"NOTICE/DEL": createSubReducer((state, action) => {
		const id = action.payload;
		delete state.notice[id];
	}),
	"NOTICE/SET": createSubReducer((state, action) => {
		const notice = action.payload;
		state.notice[notice.id] = notice;
	}),
});

export function getNotice(id: number): AsyncThunkAction<Notice> {
	return async (dispatch) => {
		const notice = await api.getNotice(id);
		dispatch(setNotice(notice));

		return notice;
	};
}

export function getNoticeList(offset: number, limit: number): AsyncThunkAction<{
	total: number;
	data: Notice[];
}> {
	return async (dispatch) => {
		const response = await api.getNoticeList(offset, limit);
		for (const notice of response.data) {
			dispatch(setNotice(notice));
		}
		dispatch(setTotal(response.total));

		return response;
	};
}
