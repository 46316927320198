import "react-app-polyfill/ie11";
import "react-app-polyfill/stable";
import {enableES5} from "immer";

import React from 'react';
import ReactDOM from 'react-dom';

import {Provider} from "react-redux";
import {BrowserRouter as Router} from "react-router-dom";
import store from "./store";

import "bulma/css/bulma.css";

import './index.css';
import "./assets/nanumbarungothic.woff";
import App from './App';

enableES5();

ReactDOM.render(
	<React.StrictMode>
		<Provider store={store}>
			<Router>
				<App />
			</Router>
		</Provider>
	</React.StrictMode>,
	document.getElementById('root')
);
