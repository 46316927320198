import {createSelector} from "reselect";
import {createAction, createReducer} from "typesafe-actions";

import * as api from "../api";
import {User} from "../types";
import type {AsyncThunkAction, State as RootState} from "./index";
import {createSubReducer} from "./util";

export type State = {
	auth?: User;
	initialized: boolean;
};

const initial: State = {
	auth: undefined,
	initialized: false,
};

export const selector = (state: RootState): State => state.user;
export const selectAuth = createSelector(selector, (state) => state.auth);
export const selectInitialized = createSelector(selector, (state) => state.initialized);

export const setAuth = createAction("USER/AUTH/SET")<User>();
export const delAuth = createAction("USER/AUTH/DEL")();
export const setInitialized = createAction("USER/INITIALIZED/SET")<boolean>();

export type Action =
	| ReturnType<typeof setAuth>
	| ReturnType<typeof delAuth>
	| ReturnType<typeof setInitialized>;
export const reducer = createReducer<State, Action>(initial, {
	"USER/AUTH/SET": createSubReducer((state, action) => {
		state.auth = action.payload;
	}),
	"USER/AUTH/DEL": createSubReducer((state) => {
		state.auth = undefined;
	}),
	"USER/INITIALIZED/SET": createSubReducer((state, action) => {
		state.initialized = action.payload;
	}),
});

async function initialize() {
	await new Promise((res) => {
		gapi.load("client:auth2", () => res());
	});
	await gapi.client.init({
		clientId: "997743852915-qocjv413bulcbt862rf55b23ct95cepd.apps.googleusercontent.com",
		scope: "https://www.googleapis.com/auth/youtube.readonly",
	});
}

export function getAuth(): AsyncThunkAction<User | undefined> {
	return async (dispatch, getState) => {
		const state = getState();
		if (!selectInitialized(state)) {
			await initialize();
		}

		const gauth = gapi.auth2.getAuthInstance();
		if (!gauth.isSignedIn.get()) {
			return undefined;
		}

		await new Promise((res, rej) => gauth.then(() => res(), rej));
		const guser = gauth.currentUser.get();
		const {id_token} = guser.getAuthResponse();
		const user = await api.postSession(id_token);
		dispatch(setAuth(user));

		return user;
	};
}

export function signIn(): AsyncThunkAction<User> {
	return async (dispatch, getState) => {
		const state = getState();
		if (!selectInitialized(state)) {
			await initialize();
		}

		const gauth = gapi.auth2.getAuthInstance();
		const guser = await gauth.signIn();
		const {id_token} = guser.getAuthResponse();
		const user = await api.postSession(id_token);
		dispatch(setAuth(user));

		return user;
	};
}

export function signOut(): AsyncThunkAction<void> {
	return async (dispatch, getState) => {
		const state = getState();
		if (!selectInitialized(state)) {
			await initialize();
		}

		const gauth = gapi.auth2.getAuthInstance();
		await gauth.signOut();
		dispatch(delAuth());
	};
}
export function patchUser(body: {name: string}): AsyncThunkAction<User> {
	return async (dispatch) => {
		const user = await api.patchUser(body);
		dispatch(setAuth(user));

		return user;
	};
}
