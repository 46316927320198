import React from 'react';

import LectureBoard from "../components/LectureBoard";
import Notice from "../components/Notice";
import './Home.css';

function Home() {
	return (
		<div className="home">
			<div className="section">
				<div className="container">
					<LectureBoard />
				</div>
			</div>
			<div className="section">
				<div className="container">
					<Notice />
				</div>
			</div>
		</div>
	);
}

export default Home;
